<template>
  <section v-loading="loading">
    <div class="all-cases-view" id="all-physicians-listing">
      <div class="navbarComponent">
        <div class="container-sm-table">
          <div class="listings-navbar">
            <el-row>
              <el-col :lg="7">
                <ul class="d-flex list-items-group-15 barcode-print">
                  <li v-if="hasPermission('physicians', 'add')">
                    <el-button
                      class="btn button-with-icon"
                      @click="addPhysician"
                    >
                      <span class="mr-8">
                        <img
                          src="@/assets/images/icons/plus-icon.svg"
                          alt="icon"
                          class="inactive"
                        />
                        <img
                          src="@/assets/images/icons/plus-icon-white.svg"
                          alt="icon"
                          class="active"
                        />
                      </span>
                      <span>ADD PHYSICIAN</span>
                    </el-button>
                  </li>
                  <li>
                    <el-button
                      class="btn button-with-icon"
                      @click="downloadExport"
                    >
                      <span class="mr-8">
                        <img
                          src="@/assets/images/icons/export-icon-with-bg-blue.svg"
                          alt="icon"
                          class="inactive"
                        />
                        <img
                          src="@/assets/images/icons/export-icon-white.svg"
                          alt="icon"
                          class="active"
                        />
                      </span>
                      <span>Export</span>
                    </el-button>
                  </li>
                </ul>
              </el-col>
              <el-col :lg="17">
                <ul
                  class="d-flex flex-wrap justify-content-end list-items-group-15"
                >
                  <li>
                    <div class="form-element search-with-filter-input">
                      <el-select
                        v-model="searchBy"
                        clearable
                        filterable
                        default-first-option
                        :reserve-keyword="false"
                        placeholder="Select Search"
                        @change="clearInputSearch"
                      >
                        <el-option
                          v-for="item in searchOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </el-select>
                      <span class="search-icon">
                        <img
                          src="@/assets/images/icons/search-icon-with-bg-red.svg"
                          alt="icon"
                          class="img-fluid"
                        />
                      </span>
                    </div>
                  </li>

                  <li v-if="isSearchByInput">
                    <div class="form-element input-with-icon search-input-lg">
                      <el-input
                        placeholder="Search"
                        v-model="search_string"
                        @input="getAutoCompleteCases()"
                        :clearable="true"
                      ></el-input>
                      <span>
                        <img
                          src="@/assets/images/icons/search-icon-with-bg-red.svg"
                          alt="icon"
                          class="img-fluid icon"
                        />
                      </span>
                    </div>
                  </li>
                  <li v-else-if="searchBy == 'fax'">
                    <div class="form-element input-with-icon search-input-lg">
                      <el-input
                        placeholder="Search"
                        v-model="search_fax"
                        @input="getAutoCompleteCases()"
                        :clearable="true"
                      ></el-input>
                      <span>
                        <img
                          src="@/assets/images/icons/search-icon-with-bg-red.svg"
                          alt="icon"
                          class="img-fluid icon"
                        />
                      </span>
                    </div>
                  </li>
                  <li v-else-if="searchBy == 'phone'">
                    <div class="form-element input-with-icon search-input-lg">
                      <el-input
                        placeholder="Search"
                        v-model="search_phone"
                        @input="getAutoCompleteCases()"
                        :clearable="true"
                      ></el-input>
                      <span>
                        <img
                          src="@/assets/images/icons/search-icon-with-bg-red.svg"
                          alt="icon"
                          class="img-fluid icon"
                        />
                      </span>
                    </div>
                  </li>
                  <li v-else-if="searchBy == 'specializations'">
                    <div class="form-element input-with-icon search-input-lg">
                      <el-input
                        placeholder="Search"
                        v-model="search_specializations"
                        @input="getAutoCompleteCases()"
                        :clearable="true"
                      ></el-input>
                      <span>
                        <img
                          src="@/assets/images/icons/search-icon-with-bg-red.svg"
                          alt="icon"
                          class="img-fluid icon"
                        />
                      </span>
                    </div>
                  </li>
                </ul>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="container-sm-table" style="padding-top: 0">
        <div class="primary-content-view-new">
          <div
            class="listings listings__table listings__all-cases"
            id="all-cases-listings"
          >
            <el-table
              :data="physiciansData.data"
              height="calc(100vh - 215px)"
              :default-sort="{
                prop: orderBy,
                order: orderType == 'asc' ? 'ascending' : 'descending',
              }"
              @sort-change="onChangeSort"
            >
              <el-table-column
                prop="name"
                label="PHYSICIAN"
                min-width="180"
                sortable="custom"
                fixed="left"
                ><template v-slot="scope"
                  ><span class="cursor" @click="onView(scope.row)">{{
                    scope.row.name
                      ? scope.row.name.includes("Dr")
                        ? scope.row.name
                        : "Dr " + scope.row.name
                      : "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="npi_code"
                label="NPI NUMBER"
                width="120"
                sortable="custom"
              >
              </el-table-column>
              <el-table-column
                prop="specializations"
                label="SPECIALIZATIONS"
                min-width="350"
                sortable="custom"
              >
                <template v-slot="scope">
                  <span class="table_cell-ellipsis">
                    {{
                      scope.row.specializations &&
                      scope.row.specializations.length
                        ? scope.row.specializations.join(", ").toUpperCase()
                        : "--"
                    }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="phone"
                label="PHONE NO"
                width="140"
                sortable="custom"
              >
              </el-table-column>
              <el-table-column
                prop="fax"
                label="FAX"
                width="140"
                sortable="custom"
              ></el-table-column>
              <el-table-column prop="status" label="STATUS" width="140">
                <template #default="scope">
                  <div class="column-status cursor" v-if="scope.row.status">
                    <el-dropdown
                      @command="(command) => updateStatus(scope.row, command)"
                      class="status-dropdown"
                    >
                      <span class="el-dropdown-link">
                        <el-tag :type="getStatusType(scope.row.status)">{{
                          scope.row.status.split("_").join(" ")
                        }}</el-tag>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu class="status-dropdown-menu">
                          <el-dropdown-item
                            command="ACTIVE"
                            :disabled="scope.row.status === 'ACTIVE'"
                            >Active</el-dropdown-item
                          >
                          <el-dropdown-item
                            command="INACTIVE"
                            :disabled="scope.row.status === 'INACTIVE'"
                            >Inactive</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Action" width="80" fixed="right">
                <template v-slot="scope">
                  <ul class="listings__action-buttons">
                    <li>
                      <el-button
                        @click="onView(scope.row)"
                        title="View Physician"
                      >
                        <img
                          class="action-active"
                          src="@/assets/images/icons/actions/view-icon.svg"
                          alt="icon" />
                        <img
                          class="action-inactive"
                          src="@/assets/images/icons/actions/view-inactive.svg"
                          alt="icon"
                      /></el-button>
                    </li>
                    <li v-if="hasPermission('physicians', 'add')">
                      <el-button
                        title="Edit Physician"
                        @click="onEdit(scope.row)"
                        class="edit-button"
                      >
                        <img
                          class="action-active"
                          src="@/assets/images/icons/actions/edit-active.svg"
                          alt="icon"
                        />
                        <img
                          class="action-inactive"
                          src="@/assets/images/icons/actions/edit-deactive.svg"
                          alt="icon"
                        />
                      </el-button>
                    </li>
                  </ul>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="physicians-pagination">
          <div class="listings listings__pagination">
            <el-pagination
              v-model:currentPage="page"
              v-model:page-size="pageSize"
              :page-sizes="[25, 50, 75, 100]"
              layout="prev, pager, next, jumper, sizes, total"
              :total="physiciansData.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <ExportPhysician
        v-if="exportDialogVisisble"
        v-on:close="exportDialogVisisble = false"
        :selected_search_string="search_string"
        :selected_search_phone="search_phone"
        :selected_search_fax="search_fax"
        :selected_search_specializations="search_specializations"
        :selected_search_status="selectedStatuses"
      >
      </ExportPhysician>
    </div>
  </section>
</template>
<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";

import { physiciansSearchOptions } from "@/config/constants/searchConstants";
import UserPermissionsHelper from "@/mixins/UserPermissionsHelper";
import FilterHelper from "@/mixins/FilterHelper";
const ExportPhysician = defineAsyncComponent(() =>
  import("@/components/physicians/ExportPhysician")
);
import {
  successNotification,
  errorNotification,
} from "@/helpers/notifications";
export default {
  data() {
    return {
      scrollPosition: null,
      searchBy: "",
      search_string: "",
      searchOptions: physiciansSearchOptions,
      exportDialogVisisble: false,
      page: 1,
      pageSize: 25,
      loading: false,
      orderBy: "created_at",
      orderType: "DESC",
      searchByKey: ["name", "npi_code"],
      search_fax: "",
      search_phone: "",
      search_specializations: "",
      fieldList: [],
      physiciansData: {},
    };
  },
  components: {
    ExportPhysician,
  },
  props: {
    selectedStatuses: {
      default: [],
    },
  },
  mixins: [UserPermissionsHelper, FilterHelper],
  computed: {
    ...mapGetters("physicians", [
      "getAllPhysicians",
      "getPhysicianStatusUpdate",
    ]),
    ...mapGetters("errors", ["getErrors", "getError"]),
    isSearchByInput() {
      return this.searchByKey.indexOf(this.searchBy) != -1 ? true : false;
    },
    getStatusType() {
      return (statusVal) => {
        const status = statusVal.trim();
        if (status == "ACTIVE") {
          return "success";
        } else if (status == "INACTIVE") {
          return "danger";
        } else {
          return "default";
        }
      };
    },
  },
  async mounted() {
    this.loading = true;
    this.setQueryParamsData();
    await this.fetchPhysiciansList();
    this.loading = false;
  },
  methods: {
    clearInputSearch() {
      if (
        this.search_string ||
        this.search_fax ||
        this.search_phone ||
        this.search_specializations
      ) {
        this.isSearchByInput = "";
        this.search_string = "";
        this.search_fax = "";
        this.search_phone = "";
        this.search_specializations = "";
        this.page = 1;
        this.fetchPhysiciansList(1);
      }
    },
    getAutoCompleteCases() {
      setTimeout(() => {
        this.page = 1;
        this.fetchPhysiciansList();
      }, 600);
    },
    downloadExport() {
      this.exportDialogVisisble = true;
    },

    async updateStatus(physician, status) {
      try {
        this.loading = true;
        let params = {
          physicianId: physician._id,
          data: {
            status: status,
          },
        };
        await this.$store.dispatch("physicians/updatePhysicianStatus", params);
        if (this.getPhysicianStatusUpdate) {
          successNotification("Status updated successfully");
          await this.fetchPhysiciansList(this.page);
        } else {
          errorNotification(this.getError || "Error in update status");
        }
        this.loading = false;
      } catch (error) {
        this.loading = true;
        console.log(error);
      }
    },
    setQueryParamsData() {
      if (this.$route.query) {
        this.page = this.$route.query.selectedPhysicianPage
          ? parseInt(this.$route.query.selectedPhysicianPage)
          : 1;
        this.pageSize = this.$route.query.selectedPhysicianPageSize
          ? parseInt(this.$route.query.selectedPhysicianPageSize)
          : 25;
        if (this.$route.query.selectedPhysicianString) {
          this.search_string = this.$route.query.selectedPhysicianString;
        }
        if (this.$route.query.selectedPhysicianSearchBy) {
          this.searchBy = this.$route.query.selectedPhysicianSearchBy;
        }
        if (this.$route.query.selectedPhysicianFax) {
          this.search_fax = this.$route.query.selectedPhysicianFax;
        }
        if (this.$route.query.selectedPhysicianPhone) {
          this.search_phone = this.$route.query.selectedPhysicianPhone;
        }
        if (this.$route.query.selectedPhysicianOrderBy) {
          this.orderBy = this.$route.query.selectedPhysicianOrderBy;
        }
        if (this.$route.query.selectedPhysicianOrderType) {
          this.orderType = this.$route.query.selectedPhysicianOrderType;
        }
        if (this.$route.query.selectedPhysicianSpecializations) {
          this.search_specializations =
            this.$route.query.selectedPhysicianSpecializations;
        }
      }
    },
    onChangeSort({ order, prop }) {
      if (order && prop) {
        this.orderBy = prop;
        this.orderType = order == "ascending" ? "asc" : "desc";
      } else {
        this.orderBy = "created_at";
        this.orderType = "DESC";
      }
      this.fetchPhysiciansList();
    },

    addPhysician() {
      this.$router.push({
        name: "AddNewPhysician",
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    onView(physicianInfo) {
      this.$router.push({
        name: "PhysicianProfileView",
        params: { physician_id: physicianInfo._id },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    onEdit(physician) {
      this.$router.push({
        name: "PhysicianEdit",
        params: { physician_id: physician._id },
        query: { ...this.$route.query, redirectRoute: this.$route.name },
      });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.page = 1;

      this.fetchPhysiciansList();
    },
    handleCurrentChange(page) {
      this.page = page;

      this.fetchPhysiciansList();
    },
    prepareParams() {
      const params = {
        page: this.page,
        limit: this.pageSize,
      };
      if (this.orderBy && this.orderType) {
        params.order_by = this.orderBy;
        params.order_type = this.orderType;
      }
      if (this.search_string) {
        params.search_string = this.search_string;
      }
      if (this.search_fax) {
        params.fax = this.search_fax;
      }
      if (this.search_phone) {
        params.phone = this.search_phone;
      }
      if (this.search_specializations) {
        params.specializations = this.search_specializations;
      }
      params.status = this.selectedStatuses;
      return params;
    },
    updateQueryParams(params) {
      let selectedParams = {
        selectedPhysicianPage: params.page,
        selectedPhysicianHospital: params.hospital,
        selectedPhysicianDateOfBirth: params.date_of_birth,
        selectedPhysician: params.ordering_physician,
        selectedPhysicianString: params.search_string,
        selectedPhysicianFax: params.fax,
        selectedPhysicianPhone: params.phone,
        selectedPhysicianOrderBy: params.order_by,
        selectedPhysicianOrderType: params.order_type,
        selectedPhysicianSpecializations: params.specializations,
        selectedPhysicianPageSize: params.limit,
        selectedPhysicianSearchBy: this.searchBy || undefined,
      };
      this.updateQueryParam(this.$route.name, selectedParams);
    },
    async fetchPhysiciansList() {
      try {
        this.loading = true;
        let params = this.prepareParams();
        await this.$store.dispatch("physicians/fetchAllPhysicians", params);
        this.physiciansData = JSON.parse(JSON.stringify(this.getAllPhysicians));
        this.updateQueryParams(params);
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.listings__all-cases {
  .el-button {
    min-height: 30px;
    padding: 0;
    background: none;
    border: none;
  }
}
.stickyNavbar {
  padding-bottom: 5px !important;
  .container-sm-table {
    padding-top: 0;
    padding-bottom: 5px;
  }
}
.search {
  width: 10px;
  height: 10px;
}
// physician list end
</style>
